<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ organization.name }} - {{ $t("COMMON.USERS") }}
      </span>
    </div>

    <user-list-table :filterOrganization="organization.id" />
  </div>
</template>

<script>
import UserListTable from "../../UserManagement/partials/UserListTable.vue";
export default {
  name: "organization-view-users",

  components: { UserListTable },

  props: ["organization"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    organization(organization) {},
  },
};
</script>
